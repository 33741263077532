import {
  attr,
  css,
  customElement,
  FASTElement,
  html,
  observable,
} from "@microsoft/fast-element";

const template = html<ChatBubble>` <div class="bubble-wrapper">
  <div class="bubble ${(x) => (x.isUser ? "user" : "bot")}">
    <slot></slot>
  </div>
</div>`;
const styles = css`
  .bubble-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 25px;
  }
  .bubble {
    border-radius: 0.5rem;
    padding: 1.2em 1.3em;
    --color: rgb(255, 243, 243);
    background: var(--color);
    box-shadow: 0 0 10px rgb(var(--black) / 10%);
    min-width: 5em;
    min-height: 1.2em;
    position: relative;
  }

  .bubble.bot::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    inset: 0px auto auto -19px;
    border-style: solid;
    border-image: none;
    border-width: 22px;
    border-color: var(--color) transparent transparent;
  }
  .bubble.user::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    inset: 0px -20px auto auto;
    border-style: solid;
    border-image: none;
    border-width: 22px;
    border-color: var(--color) transparent transparent transparent;
  }

  .bubble.user {
    align-self: flex-end;
  }

  .bubble.bot {
    align-self: flex-start;

    --color: #f0f8ff;
  }
`;

@customElement({
  name: "chat-bubble",
  template,
  styles,
})
export class ChatBubble extends FASTElement {
  @attr isUser: boolean = false;
}
