import {
  FASTElement,
  html,
  css,
  customElement,
  attr,
  ref,
  observable,
} from "@microsoft/fast-element";
import { BubbleComponent } from "./bubble";
import { ForwardDialog } from "./forward";
import { SearchComponent } from "./search";
import { state } from "./store";

const template = html<ChatElement>`
  <section class="chat ${(x) => (x.visible ? " visible" : "hidden")}" style="z-index: ${x => x.state.zIndex};">
    <h2>Chat</h2>
    <button @click=" ${(x) => (x.bubble.isOpen = false)}">
      <material-icon name="close"></material-icon>
    </button>
    <p>
      Unser Chatbot hilft dir beim Durchsuchen unserer Website. Es handelt sich
      bei den Antworten nicht um geprüfte Ratschläge. Erfahre
      <a href="#">hier</a> mir über dieses Tool.
    </p>
    <search-bot-component ${ref("search")} :url=${x=> x.url} :chat=${(x) => x}
      ></search-bot-component>
  </section>
  <forward-dialog :chat=${(x) => x} ${ref("dialog")}></forward-dialog>
`;
const styles = css`
  .chat {
    max-width: 600px;
    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    padding-bottom: 1em;
    border-radius: 0.5rem;
    background: white;
    position: fixed;
    right: 30px;
    top: calc(100vh);
    z-index: -1;
    display: grid;
    grid-template-areas:
      "title close"
      "chat chat"
      "intro intro";
    grid-template-rows: auto auto 1fr;
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
    transition: all 200ms ease-out;
    opacity: 0;
  }

  @media (max-width: 620px) {
    .chat {
      right: 3px;
    }
  }

  .chat.visible {
    opacity: 1;
    top: max(10px, calc(100vh - 810px));
  }

  h2,
  p,
  search-bot-component {
    margin: 0 2rem;
  }

  h2 {
    color: rgb(var(--red));
    grid-area: title;
    align-self: center;
    font-size: 1.2rem;
    margin-top: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(var(--black) / 10%);
  }

  button {
    grid-area: close;
    font-size: 1rem;
    justify-self: end;
    align-self: start;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0.5rem;
  }

  p {
    grid-area: intro;
    font-size: small;
  }

  search-bot-component {
    grid-area: chat;
  }

  :root {
    --black: 47 47 47;
  }
`;

@customElement({
  name: "chat-element",
  template,
  styles,
})
export class ChatElement extends FASTElement {
  @attr forwardUrl = "";
  @attr({ mode: "boolean" }) visible: boolean = false;

  @attr bubble!: BubbleComponent;
  @attr toggle!: any;
  @attr url!: string;
  @observable state = state;

  dialog!: ForwardDialog;
  search!: SearchComponent;
}
