import {
  attr,
  css,
  customElement,
  FASTElement,
  html,
  observable,
  when,
} from "@microsoft/fast-element";
import { ForwardDialog } from "./forward";

const template = html<MoreElement>`<chat-bubble>
  <span>
    Hast du weitere Fragen, oder möchtest du an eine Fachperson weitergeleitet
    werden?
  </span>
  <button @click=${(x, c) => x.forward()}>
    <material-icon name="forward"> Fachstelle kontaktieren </material-icon>
  </button>
</chat-bubble> `;

@customElement({
  name: "more-element",
  template,
  styles: css`
    button {
      font-size: 1rem;
      font-weight: bold;
      border-radius: 0.5rem;
      box-sizing: border-box;
      padding: 0.3em 0.7em;
      color: rgb(var(--white));
      background: rgb(var(--red));
      border-radius: 9999px;
      cursor: pointer;
      border: none;
      margin-top: 0.8em;
      margin-bottom: 0.3em;
    }
  `,
})
export class MoreElement extends FASTElement {
  @attr dialog!: ForwardDialog;

  async forward() {
    this.dialog.openDialog();
  }
}
