import {
  attr,
  css,
  customElement,
  FAST,
  FASTElement,
  html,
  observable,
  ref,
  when,
} from "@microsoft/fast-element";
import { ChatElement } from "./chat";

const template = html<ForwardDialog>` <dialog ${ref("dialog")}>
  <button id="close" @click="${(x) => x.dialog.close()}">
    <material-icon name="close"></material-icon>
  </button>
  <div class="wrapper">
    <h3>Wenden Sie sich an unsere Fachstelle</h3>
    <p>
      Wir beraten Sie kompetent und schnell. Mit dem Absenden erklären Sie sich
      damit einverstanden, dass die Fachstelle Ihren Angaben zum Zweck der
      Beantwortung ihrer Frage verarbeiten darf.
    </p>
    <form
      ${ref("form")}
      @submit=${(x, c) => x.onSubmit(c.event)}
    >
    <div>
      <label>E-Mail</label>
      <input @input=${(x) => x.onChange()} name="email" type="text" />
      ${when(
        (x) => x.email && !x.isEmailValid(),
        html`
          <small class="error"
            >Bitte eine gültige E-Mail Adresse eingeben!</small
          >
        `
      )}
      <span >
  </div>

  <div>
      <label>Nachricht</label>
      <textarea @change="${(x) => x.onChange()} name="message"></textarea>
      <small>Beschreiben Sie Ihr Anliegen möglichst präzis, damit wir Ihnen schnell helfen können</small>
    </div>
      <button ?disabled="${(x) => !x.isReady()}" id="submit" type="submit">
        <material-icon name="send"><span>Anfrage senden</span></material-icon>
      </button>

    </form>
  </div>
</dialog>`;

const styles = css`
  dialog {
    border: none;
    border-radius: 0.5rem;
    width: 50%;

    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    padding: 0;
    max-width: 720px;
    position: relative;
  }

  .error {
    color: rgb(var(--red));
  }

  .wrapper {
    display: grid;
    grid-template-areas:
      ". close"
      "title title"
      "intro intro"
      "form form";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto auto;
    padding: 3rem 3.5rem;
  }
  form {
    grid-area: form;
    display: grid;
    grid-template-columns: auto;
  }

  h3 {
    color: rgb(var(--red));
    margin-top: 0;
    margin-bottom: 0;
    grid-area: title;
  }

  p {
    grid-area: intro;
  }

  label {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.5em;
  }

  form > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    gap: 8px;
  }

  textarea {
    height: 12em;
    resize: none;
  }

  input,
  textarea {
    border-radius: 0.5rem;
    padding: 0.3em 0.7em;

    box-sizing: border-box;
    outline: inherit;
    border: 1px solid rgb(var(--black) / 10%);
    font-size: 1rem;
  }

  dialog::backdrop {
    background: rgb(var(--black) / 50%);
  }

  input:focus-visible,
  textarea:focus-visible {
    box-shadow: 0 0 0 4px rgb(var(--focus) / 100%),
      inset 0 0 0 9999px rgb(var(--focus) / 10%);

    outline-offset: 0px;
  }
  #submit {
    cursor: pointer;
    justify-self: start;
  }

  #close {
    grid-area: close;
    background: white;
    border: none;
    cursor: pointer;
    align-self: start;
    position: absolute;
    top: 1em;
    right: 1em;
  }

  button#submit {
    font-size: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 0.3em 0.7em;
    color: rgb(var(--red));
    background: white;
    border-radius: 9999px;
    cursor: pointer;
    border: solid 1px rgb(var(--red));
  }

  button#submit:disabled {
    cursor: not-allowed;
    border: solid 1px rgb(var(--red) / 20%);
    color: rgb(var(--red) / 20%);
  }

  button span {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

@customElement({ name: "forward-dialog", template, styles })
export class ForwardDialog extends FASTElement {
  @attr chat!: ChatElement;
  dialog!: HTMLDialogElement;
  form!: HTMLFormElement;

  @observable email: string = "";
  @observable message: string = "";

  openDialog() {
    this.dialog.showModal();
  }

  isEmailValid() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(this.email);
  }

  isReady() {
    return this.isEmailValid() && this.message != "";
  }

  onChange() {
    const data = new FormData(this.form);
    this.email = data.get("email") as string;
    this.message = data.get("message") as string;
  }

  async onSubmit(e: Event) {
    await this.chat.search.forwardConversation(this.email, this.message);
    this.dialog.close();
  }
}
