import {
  FASTElement,
  attr,
  html,
  customElement,
  when,
  css,
  observable,
} from "@microsoft/fast-element";
import { state } from "./store";

const template = html<BubbleComponent>`
  <button @click="${(x, c) => x.toggle()}" style="z-index: ${x => x.state.zIndex};">
    <material-icon :name=${(x) => (x.isOpen ? "close" : "chat")}
      ></material-icon>
  </button>
  <chat-element :url="${x => x.url}" :bubble="${(x) => x}" ?visible=${(x) => x.isOpen}
    ></chat-element>
`;
const styles = css`
  button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    border: none;
    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    background: white;
  }
`;

@customElement({
  name: "bubble-component",
  template,
  styles,
})
export class BubbleComponent extends FASTElement {
  @observable isOpen: boolean = false;
  @attr url!: string;
  @observable state = state;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
