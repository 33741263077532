import { provideFASTDesignSystem } from "@microsoft/fast-components";
import { SearchComponent } from "./search";
import { AnswersComponent } from "./answer";
import { ResultComponent } from "./result";
import { SpinnerComponent } from "./spinner";
import { ChatElement } from "./chat";
import { ChatBubble } from "./chatbubble";
import { MoreElement } from "./more";
import { BubbleComponent } from "./bubble";
import { MaterialIcon } from "./material-icon";
import { ForwardDialog } from "./forward";

provideFASTDesignSystem().register(
  SearchComponent,
  AnswersComponent,
  ResultComponent,
  SpinnerComponent,
  BubbleComponent,
  ChatElement,
  ChatBubble,
  MoreElement,
  ForwardDialog,
  MaterialIcon
);
